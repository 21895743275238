
$(document).ready(function() {
    $('.photos').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $(function () {
         $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
     } );


    /* START menu for tablets and mobiles */
    $("#head a.mobile-btn").click(function() {
        if($('#head .nav').hasClass('active')){
            $('#head .nav').removeClass('active');
            $(this).removeClass('active');
            $(this).find('i.icon').addClass('icon-menu').removeClass('icon-close');
        }else{
            $('#head .nav').addClass('active');
            $(this).addClass('active');
            $(this).find('i.icon').removeClass('icon-menu').addClass('icon-close');
        }
    });

    var owl = $('.owl-carousel');

    owl.owlCarousel({
        loop: true,
        margin: 0,
        dots:true,
        nav: false,
        responsive : {
            // breakpoint from 0 up
            0 : {
                items: 1
            },
            600 : {
                items: 2
            }
        },
        autoplay:true,
        autoplayTimeout:5000
    });

});
